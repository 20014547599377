import React from "react";
import "./menuSidebar.css";
import "./sidebar.css";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import axios from "axios";
import { toast } from "react-toastify";
import Logo from "../../logoohana.png";

function MenuSidebar(props) {
  const userRoles = {
    admin: "admin",
    employee: "employee",
  };
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("role");

  const handleReset = async () => {
    try {
      const res = await axios.put(
        "https://api.violetholidays.com/resetleads",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(res.data);
      toast.success(res.data.message, {});
    } catch (e) {
      // console.log(e);
      toast.error(e.response.data.message, {});
    }
  };
  return (
    <>
      <div className="menuSidebar">
        <div className="sidebarWrapper">
          <div className="closeIcon">
            <CloseIcon
              onClick={() => props.setVisibility()}
              style={{ fontSize: "28px", color: "red" }}
            />
          </div>
          <div className="logo">
            <img alt="logo" src={Logo} className="logo-img" />
            <span
              style={{
                textAlign: "center",
                width: "100%",
                fontFamily: "Montserrat",
                fontWeight: "500",
              }}
            >
              Ohana Holidays
            </span>
          </div>
          <div className="sidebarMenu">
            <ul className="sidebarList">
              <Link
                to="/home"
                className="links"
                onClick={() => props.setVisibility()}
              >
                <div className="nav-links">
                  <HomeOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Home</li>
                </div>
              </Link>
              <Link
                to="/clients"
                className="links"
                onClick={() => props.setVisibility()}
              >
                <div className="nav-links">
                  <PeopleOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Clients</li>
                </div>
              </Link>
              <Link
                to="/postclients"
                className="links"
                onClick={() => props.setVisibility()}
              >
                <div className="nav-links">
                  <PeopleOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Post Clients</li>
                </div>
              </Link>
              {user === userRoles.admin ? (
                <Link
                  to="/employees"
                  className="links"
                  onClick={() => props.setVisibility()}
                >
                  <div className="nav-links">
                    <SupervisorAccountOutlinedIcon className="icons" />
                    <li className="sidebarListItem">Employees</li>
                  </div>
                </Link>
              ) : (
                ""
              )}

              <Link
                to="/completed"
                className="links"
                onClick={() => props.setVisibility()}
              >
                <div className="nav-links">
                  <AssignmentTurnedInOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Completed</li>
                </div>
              </Link>
              <Link
                to="/cancelled"
                className="links"
                onClick={() => props.setVisibility()}
              >
                <div className="nav-links">
                  <CancelPresentationOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Cancelled</li>
                </div>
              </Link>
              {user === userRoles.admin ? (
                <div
                  className="nav-links"
                  onClick={() => props.setVisibility()}
                >
                  <RestartAltOutlinedIcon className="icons" />
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    className="sidebarListItem"
                    onClick={handleReset}
                  >
                    Reset Leads
                  </button>
                </div>
              ) : (
                ""
              )}
            </ul>
          </div>
          <Link className="links" to="/" onClick={() => localStorage.clear()}>
            <div className="logoOutWrapper">
              <LogoutOutlinedIcon />
              <span className="sidebarListItem logoutLink">Logout</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default MenuSidebar;
