import React from "react";
import "./header.css";
import Logo from "../../logoohana.png";

function Header() {
  return (
    <div className="headerWrapper">
      <div className="logoContainer">
        <img src={Logo} alt="avatar" className="logoImg" />
      </div>
      <div className="loginTextContainer">
        <h2 className="loginText">Log in to Ohana Holidays</h2>
      </div>
      <div>
        <p className="signInText">Please sign in using your account details.</p>
      </div>
    </div>
  );
}

export default Header;
