import "./header.css";
import "./login.css";
import { useFormik } from "formik";
import * as yup from "yup";
import "./form.css";
import axios from "axios";
import "../client/addClient.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import Logo from "../../logoohana.png";

const ResetPassword = () => {
  let { token } = useParams();
  const history = useHistory();
  // console.log(token);
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .required("* Field is required")
        .min(8, "Password must be atleast 6 characters"),
      confirmPassword: yup
        .string()
        .required("* Field is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must be same"),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      try {
        const res = await axios.put(
          "https://api.violetholidays.com/resetpassword",
          {
            token,
            ...values,
          }
        );
        // console.log(res.data);
        if (res.status === 200) {
          toast.success(res.data.message, { delay: 0 });
          setTimeout(() => {
            history.push("/");
          }, 2000);
        }
      } catch (e) {
        // console.log(e.response.data.message);
        toast.error(e.response.data.message, { delay: 0 });
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
    },
  });

  return (
    <div className="loginWrapper">
      <div className="headerWrapper">
        <div className="logoContainer">
          <img src={Logo} alt="avatar" className="logoImg" />
        </div>
        <div>
          <p className="signInText">Set new password for your account</p>
        </div>
      </div>
      <div className="formWrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="loginFormContainer">
            <div className="formItem">
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                autoComplete="off"
                className={`login-form-control  ${
                  formik.touched.newPassword && formik.errors.newPassword
                    ? "form-error"
                    : ""
                }`}
                placeholder="Enter New password"
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="formErrorMsg">{formik.errors.newPassword}</div>
              ) : null}
            </div>
            <div className="formItem">
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                autoComplete="off"
                className={`login-form-control  ${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "form-error"
                    : ""
                }`}
                placeholder="Confirm Password"
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="formErrorMsg">
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className="login-btn">
            <button type="submit" className="primary ">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
