import React, { useEffect } from "react";
import { useState } from "react";
import MenuSidebar from "../sidebar/MenuSidebar";
import "./topbar.css";
import MenuIcon from "@mui/icons-material/Menu";

function Topbar() {
  const [date, setDate] = useState(new Date());

  const [isMobile, setIsmobile] = useState(false);

  useEffect(() => {
    setInterval(() => tick(), 1000);
  }, []);

  const tick = () => {
    setDate(new Date());
  };

  const handleToggleClick = () => {
    setIsmobile((o) => !o);
  };
  return (
    <>
      <div className="topbar">
        <div className="topbarWrapper">
          <div className="topbarLeft">
            <span className="menu">
              <MenuIcon className="menuIcon" onClick={handleToggleClick} />
            </span>
            <span className="logo-text">Ohana Holidays</span>
          </div>
          <div className="topbarRight">
            <span className="timeComponent">
              {date.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })}
            </span>
          </div>
        </div>
      </div>
      {isMobile ? (
        <>
          <div className="backdrop" onClick={handleToggleClick}></div>
          <MenuSidebar mobile={isMobile} setVisibility={handleToggleClick} />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Topbar;
